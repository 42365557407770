/*
## File Description ##
- This functional component is used for updating the GeoTiff layer on the map.
- It uses the georaster-layer-for-leaflet library to display the tiff files on the map.
- It uses the Cache.js file to fetch the tiff files from the cache.
*/

import { useEffect, useRef, useState } from "react";
import proj4 from "proj4";
import { useLeafletContext } from "@react-leaflet/core";
import { useMap } from "react-leaflet";
import GeoRasterLayer from "georaster-layer-for-leaflet";
import myCache, { cacheConfig } from "../Cache.js";
import parseGeoraster from "georaster";
const fetch_config = require("../config/fetch.config.json");
import { getTiff_HTTP } from "../FetchReq.js";

window.proj4 = proj4;

const GeotiffLayer = ({ features, url, options, country, interactiveClear }) => {
  // Set up a ref to the GeoRasterLayer instance //
  const geoTiffLayerRef = useRef();
  // Get the map and layer container from the context //
  const context = useLeafletContext();
  // Get the map instance from the context //
  const map = useMap();

  const featureLevel = features.featureLevel;
  const featureId = features.featureId;
  const URL_split = url.split("/");
  const prediction = URL_split[1];
  const subdirectory = URL_split[2];
  const filename = URL_split[3];

  useEffect(() => {
    const container = context.geoTiffLayerRef || map;

    // Clear all the geoTiff Layers //
    container.eachLayer((layer) => {
      if (layer instanceof GeoRasterLayer) {
        container.removeLayer(layer);
      }
    });

    const intervalID = setInterval(() => {
      if (!myCache.has(filename + "_" + featureId)) {
        // console.log("Tiff file is not in cache");
      } else if(interactiveClear){
        // console.log("Interactive Clear option")
      } else {
        const tiffFile_b64_string = myCache.get(filename + "_" + featureId);
        let tiffFile_arrayBuffer = Uint8Array.from(
          atob(tiffFile_b64_string),
          (c) => c.charCodeAt(0)
        ).buffer;
        parseGeoraster(tiffFile_arrayBuffer).then((georaster) => {
          let geoTiffLayer = new GeoRasterLayer({
            georaster: georaster,
            pixelValuesToColorFn: options.pixelValuesToColorFn,
            opacity: options.opacity,
            resolution: options.resolution
          });
          // add the GeoRasterLayer instance to the ref //
          geoTiffLayerRef.current = geoTiffLayer;
          container.addLayer(geoTiffLayerRef.current);
        });

        clearInterval(intervalID);
      }
    }, 1000);

    setTimeout(() => {
      clearInterval(intervalID);
    }, 10000);

    return () => {
      clearInterval(intervalID);
    };
  }, [context, features, url, map, !options]);
  return null;
};

export default GeotiffLayer;