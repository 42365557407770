import L from "leaflet";
import { useEffect } from "react";
import proj4 from "proj4";
import { useLeafletContext } from "@react-leaflet/core";
import { useMap } from "react-leaflet";
import myCache, { cacheConfig } from "../Cache.js";
const server_config = require("../config/server.config.json");

window.proj4 = proj4;

const getChildrenShapeFile = async (country, parentFeatureId) => {
  const endpoint = `${server_config["LOCALHOST_SERVER_URL"]}/geoTiff/childrenLevel/${country}/${parentFeatureId}/`;
  const resJSON = await fetch(endpoint, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => {
    return res.json()
  }).then((resJSON) => {
    return resJSON;
  }).catch((err) => {
    console.log(err);
  });
  return resJSON;
}

// This component handles different levels of areas in shapefiles.
const LevelButton = ({ refOtherGraphs, mapPointer, makeCountryChoice}) => {
  let reference = mapPointer.state;
  const context = useLeafletContext();
  const map = useMap();

  useEffect(() => {
    // This function will create a button, call it as level-up button.
    // By clicking this button users can view a higher level of administrative unit plotted on the map as compared to the current one.
    // For example, if map is displaying district boundaries then when user clicks this button it will display state boundaries and the district boundaries will be deleted.
    const control = L.Control.extend({
      options: { position: "topleft" },
      onAdd: function () {
        const btn = L.DomUtil.create("button");
        btn.title = "Higher Adm Level";
        btn.textContent = "↑";
        btn.className = "customButton";
        // This defines onClick functionality
        btn.onclick = async (e) => {
          // check fromWhere //
          if (reference.fromWhere === "interactive") {

            const country = myCache.get("country");
            if (!reference.interactiveFocus) {
              makeCountryChoice(null);
            } else {
              reference.interactiveFocus = false
              reference.currentFeature = mapPointer.country
              mapPointer.setFeatureLevel(mapPointer.state.featureLevel);
              mapPointer.state.map.current.fitBounds(mapPointer.state.featureBounds[0]);
              mapPointer.state.prevFeatureId.pop()
              reference.onMapUpdate(reference.currentFeature)
              await mapPointer.setTiffUrl(reference.tiffUrl, 0, reference.currentFeature)
              mapPointer.forceUpdate();
            }

          } else {

            if ( reference.fL == 0) {
              makeCountryChoice(null);
              return;
            }

            reference.geoJsonRef.current.clearLayers();
            for (let i = 0; i < mapPointer.refOtherGraphs.length; i++) {
              mapPointer.refOtherGraphs[i].current.state.geoJsonRef.current.clearLayers();
            }
            reference.prevFeatureId.pop();
            reference.currentFeature = reference.prevFeatureId[reference.prevFeatureId.length - 1]
            reference.map.current.fitBounds(reference.featureBounds[reference.featureBounds.length - 1])
            reference.featureBounds.pop();
            for (let i = 0; i < mapPointer.refOtherGraphs.length; i++) {
              mapPointer.refOtherGraphs[i].current.state.prevFeatureId.pop();
              mapPointer.refOtherGraphs[i].current.state.currentFeature = mapPointer.refOtherGraphs[i].current.state.prevFeatureId[mapPointer.refOtherGraphs[i].current.state.prevFeatureId.length - 1]
              mapPointer.refOtherGraphs[i].current.state.map.current.fitBounds(mapPointer.refOtherGraphs[i].current.state.featureBounds[mapPointer.refOtherGraphs[i].current.state.featureBounds.length - 1])
              mapPointer.refOtherGraphs[i].current.state.featureBounds.pop();
            }
            const tempData = await getChildrenShapeFile(mapPointer.country, reference.currentFeature)
            reference.data = tempData;
            reference.geoJsonRef.current.addData(reference.data);
            reference.fL = reference.fL - 1;
            for (let i = 0; i < mapPointer.refOtherGraphs.length; i++) {
              mapPointer.refOtherGraphs[i].current.state.data = tempData;
              mapPointer.refOtherGraphs[i].current.state.geoJsonRef.current.addData(mapPointer.refOtherGraphs[i].current.state.data);
              mapPointer.refOtherGraphs[i].current.state.fL = mapPointer.refOtherGraphs[i].current.state.fL - 1;
            }
            await mapPointer.setTiffUrl(reference.tiffUrl, reference.fL, reference.currentFeature);
            for (let i = 0; i < mapPointer.refOtherGraphs.length; i++) {
              await mapPointer.refOtherGraphs[i].current.setTiffUrl(mapPointer.refOtherGraphs[i].current.state.tiffUrl, mapPointer.refOtherGraphs[i].current.state.fL, mapPointer.refOtherGraphs[i].current.state.currentFeature);
            }
            mapPointer.forceUpdate();
            for (let i = 0; i < mapPointer.refOtherGraphs.length; i++) {
              mapPointer.refOtherGraphs[i].current.forceUpdate();
            }
            
          }
        };
        return btn;
      },
    });
    var levelControl = new control();
    context.map.addControl(levelControl);
    return () => {
      context.map.removeControl(levelControl);
    };
  }, [context, mapPointer, map]);
  return null;
};

export default LevelButton;
