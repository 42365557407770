/*
## File Description ##
- This file is used to create a websocket connection to the server and fetch the tiff files.
- It uses the georaster library to parse the tiff files.
- It uses the Cache.js file to store the tiff files in the cache.
*/

import { displayError } from "./components/Utils";
import myCache from "./Cache";
import parseGeoraster from "georaster";
import GeoRasterLayer from "georaster-layer-for-leaflet";

const MAX_TIMEOUT = require("./config/fetch.config.json")["MAX_TIMEOUT"];

function clearTiffFile(fileName) {
  // This function is used to clear the tiff files from the cache upon ws termination //
  // console.log("Clearing tiff files from cache", fileName);
  if (fileName != null) {
    let keys = myCache.keys();
    // console.log(keys);
    for (let i = 0; i < keys.length; i++) {
      if (keys[i].includes(fileName)) {
        // console.log("Deleting " + keys[i]);
        myCache.del(keys[i]);
      }
    }
  }
}

function createWSHandlerInstance(endpoint) {
  let ws = new WebSocketHandler();

  ws.setEndpoint(endpoint);
  ws.setOnMessage((event) => {
    let json_data = JSON.parse(event.data);
    if (json_data["error"] && json_data["errorCode"] == 404) {
      displayError("Error: " + json_data["error"]);
    } else if (json_data["tiffFile"] != null) {
      let tiffFile_b64_string = json_data["tiffFile"];
      let fn = json_data["fileName"] + "_" + json_data["featureID"];
      myCache.set(fn, tiffFile_b64_string);
    } else if (json_data["type"] == "ping") {
      // console.log("PING");
      ws.ws.send(JSON.stringify({ type: "pong" }));
    }
  });

  ws.setOnClose(() => {
    ws.close();
    // console.log("WS Closed");
  });

  return ws;
}


// Custom Handler for WebSocket //
class WebSocketHandler {
  constructor(props) {
    this.ws = null;
    this.endpoint = null;
    this.intervalID = null;
    this.timeoutID = null;
    this.onMessage = null;
    this.onOpen = null;
    this.onClose = null;
    this.onError = null;
    this.currentTiffFile = null;
  }

  setEndpoint(endpoint) {
    this.endpoint = endpoint;
  }

  setCurrentTiffFile(tiffFile) {
    this.currentTiffFile = tiffFile;
  }

  setOnMessage(event) {
    this.onMessage = event;
  }

  setOnOpen(onOpen) {
    this.onOpen = onOpen;
  }

  setOnClose(onClose) {
    this.onClose = onClose;
  }

  setOnError(onError) {
    this.onError = onError;
  }

  isInactive() {
    return this.ws == null || this.ws.readyState === 3;
  }
  
  async connect(endpt = this.endpoint) {
    if (this.ws == null || this.ws.readyState === 3) {
      // console.log("WS connecting");
      this.ws = new WebSocket(endpt);
      this.ws.onmessage = this.onMessage;
      this.ws.onopen = this.onOpen;
      this.ws.onclose = this.onClose;
      this.ws.onerror = this.onError;
    } else if (this.ws.readyState === 1) {
      // console.log("WS already connected");
      await this.close();
      clearTiffFile(this.currentTiffFile);
      this.currentTiffFile = null;
      // sleep for 3 seconds //
      await new Promise((r) => setTimeout(r, 3000));
      await this.connect();
    }
  }

  async send(params) {
    // console.log("NEW REQUEST");
    await new Promise((r) => {
      r(this.connect());
    });

    // Create a interval to check if the connection is still alive //
    this.intervalID = setInterval(() => {
      // console.log("Checking WS connection");
      if (this.ws != null && this.ws.readyState === 1) {
        // console.log("SENT");
        this.ws.send(JSON.stringify(params));
        this.currentTiffFile = params["fileName"];
        clearInterval(this.intervalID);
      }
      // console.log(this.ws);
    }, 1000);

    const timeoutID = setTimeout(() => {
      clearInterval(this.intervalID);
    }, MAX_TIMEOUT);
  }

  async close() {
    this.ws?.close();
    this.ws = null;
    // console.log("WS closed");
    // clearTimeout(this.timeoutID);
  }
}

export { createWSHandlerInstance };
