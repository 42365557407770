/*
## File Description ##
- This file is the entry point for fetching the tiff files from the server. 
- It uses the WebSocketHandler.js file to create a websocket connection to the server per request
  and fetch the tiff files.
*/

import myCache, { cacheConfig } from "./Cache.js";
import { dateToString, displayError } from "./components/Utils.js";
const server_config = require("./config/server.config.json");
import { createWSHandlerInstance } from "./WebSocketHandler.js";

let listOfInstances = [];

// Destroy Inactive Websocket Connections //
setInterval(() => {
  listOfInstances.forEach((instance) => {
    if (instance.isInactive()) {
      listOfInstances = listOfInstances.filter((i) => i !== instance);
    }
  });
}, 10000);

// This function is used to get the lower level tiff files //
async function getTiff(tiffUrl, highestFeatureId, featureId) {
  console.log("wss !")
  const URL_split = tiffUrl.split("/");
  const prediction = URL_split[1];
  const subdirectory = URL_split[2];
  const filename = URL_split[3];

  let meanOr00 = "mean"
  if(prediction=="nowcast") {
    meanOr00 = "00"
  }
  const fileNameInBackend = filename

  let params_1 = {
    prediction: prediction,
    subDirectory: subdirectory,
    fileName: fileNameInBackend,
    fileNameInFrontend: filename,
    highestFeatureID: highestFeatureId,
    featureId: featureId
  };
  if (myCache.has(filename + "_" + featureId)) { // PT: removed params_1.
    return;
  }

  const endpoint = server_config["WS_LOCALHOST_SERVER_URL"] + "/geoTiffWS/";

  const WSH = createWSHandlerInstance(endpoint);
  listOfInstances.push(WSH);
  WSH.send(params_1);
}

async function getTiff_HTTP(tiffUrl, highestFeatureId, level, featureId) {
  const URL_split = tiffUrl.split("/");
  const prediction = URL_split[1];
  const subdirectory = URL_split[2];
  const filename = URL_split[3];

  let meanOr00 = "mean"
  if(prediction=="nowcast") {
    meanOr00 = "00"
  }
  const fileNameInBackend = filename

  let params_1 = {
    prediction: prediction,
    subDirectory: subdirectory,
    fileName: fileNameInBackend,
    fileNameInFrontend: filename,
    highestFeatureID: highestFeatureId,
    level: level,
    featureId: featureId
  };

  if (myCache.has(filename + "_" + featureId)) { // PT: removed params_1.
    return;
  }

  const endpoint = server_config["LOCALHOST_SERVER_URL"] +
  "/geoTiff/" +
  params_1.prediction +
  "/" +
  params_1.subDirectory +
  "/" +
  params_1.fileName +
  "/" +
  params_1.highestFeatureID +
  "/" +
  params_1.level +
  "/" +
  params_1.featureId

  fetch(endpoint)
  .then(res => res.json())
  .then(json_data => {
    if(!json_data["tiffFile"]) {
      displayError("Error: Data Not Found");
      return;
    }
    let tiffFile_b64_string = json_data["tiffFile"];
    let fn = json_data["fileName"] + "_" + json_data["featureId"];
    myCache.set(fn, tiffFile_b64_string);
  })
  .catch((error) => {
    displayError("Error: Data Not Found");
  });
}

// This function is used to get the lower level tiff files //
async function getTiff_HTTPReq(tiffUrl, highestFeatureId, featureId, level) {
  const URL_split = tiffUrl.split("/");
  const prediction = URL_split[0];
  const subdirectory = URL_split[1];
  const filename = URL_split[2];

  let params_1 = {
    prediction: prediction,
    subDirectory: subdirectory,
    fileName: filename,
    highestFeatureID: highestFeatureId,
  };
  if (myCache.has(filename + "_" + featureId)) {
    return;
  }

  // Fetch //

  const params = {
    prediction: params_1.prediction,
    subDirectory: params_1.subDirectory,
    fileName: params_1.fileName,
    highestFeatureID: params_1.highestFeatureID,
    featureID: featureId,
    featureLevel: level,
  };
  if (myCache.has(params.fileName + "_" + featureId)) {
    return;
  }
  fetch(
      server_config["LOCALHOST_SERVER_URL"] +
      "/geoTiff/" +
      params.prediction +
      "/" +
      params.subDirectory +
      "/" +
      params.fileName +
      "/" +
      params.highestFeatureID +
      "/" +
      params.featureLevel +
      "/" +
      params.featureID,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((response) => {
      if (response.ok) {
        // return response.json();
        response.arrayBuffer().then((data) => {
          // convert arrayBuffer to base64 string
          data = btoa(
            new Uint8Array(data).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ""
            )
          );
          // data = btoa(String.fromCharCode.apply(null, new Uint8Array(data)))
          myCache.set(params.fileName + "_" + params.featureID, data);
        });
      }
      else{
        displayError("Error: Data Not Found");
      }
    })
    .catch((error) => {
      displayError("Error: Data Not Found");
    });
}

async function getTSData(params) {
  // console.log(params["selectedDate"])
  if(params["highestFeatureId"] == undefined) {
    return [];
  }
  const endpoint =
    server_config["LOCALHOST_SERVER_URL"] +
    "/TSData/" +
    params["periodicity"] +
    "/" +
    params["selectedDate"] +
    "/" +
    params["prediction"] +
    "/" +
    params["variableCode"] +
    "/" +
    params["featureId"] +
    "/" +
    params["highestFeatureId"] +
    "/" +
    params["featureLevel"];

  const dataToPlot = fetch(endpoint, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => (response.ok ? response.json() : []))
    .catch((error) => {
      // console.log(error);
      return [];
    });
  return dataToPlot;
}

async function getLastMapUpdateDate() {
  const endpoint = server_config["LOCALHOST_SERVER_URL"] + "/webhooks/lastMapUpdateDate"

  const lastMapUpdateDate = fetch(endpoint, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => (response.ok ? response.json() : []))
    .catch((error) => {
      // console.log(error);
      return [];
    });
  return lastMapUpdateDate;
}

export {
  getTiff,
  getTSData,
  getLastMapUpdateDate,
  getTiff_HTTPReq,
  getTiff_HTTP
};
