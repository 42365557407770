import React, { Component, useEffect } from "react";
import { MapContainer, TileLayer, GeoJSON } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import GeotiffLayer from "./GeoTiffLayer.js";
import LevelButton from "./LevelButton.js";
import Legends from "./Legends.js";
import { DownloadFile, HTMLtoPNG } from "./DownloadFile";
import { getClippedTiff } from "./Utils";
import { getTiff_HTTP } from "../FetchReq.js";
const server_config = require("../config/server.config.json");
const fetch_config = require("../config/fetch.config.json");
const country_adm_level_config = require("../config/country_adm_level.config.json");
const coord_zoom_config = require("../config/coord_zoom.config.json");

// This is custom Map Class which integrates shapefiles and tiff Files on a Map.
export default class MapTiff extends Component {
  constructor(props) {
    super(props);
    this.predictionType = props.predictionType;
    this.enableLegends = props.enableLegends ? props.enableLegends : false;
    this.disableLevelButton = props.disableLevelButton
      ? props.disableLevelButton
      : false;
    this.refOtherGraphs = props.refOtherGraphs ? props.refOtherGraphs : [];
    this.handleEachFeature = this.handleEachFeature.bind(this);
    this.customHandleEachFeature = props.customHandleEachFeature
      ? props.customHandleEachFeature
      : this.handleEachFeature;
    this.makeCountryChoice = props.makeCountryChoice;
    this.country = props.country
    this.legendsData = props.legendsData;
    this.highestData = props.highestData
    this.zoomControl = props.zoomControl ? props.zoomControl : true;
    let arrayOfCoordinates = props.highestData["features"][0]["geometry"]["coordinates"][0][0]
    let first_corr = arrayOfCoordinates.map(element => element[0]);
    let second_corr = arrayOfCoordinates.map(element => element[1]);
    let first_avg = first_corr.reduce((a, b) => a+b, 0)/first_corr.length
    let second_avg = second_corr.reduce((a, b) => a+b, 0)/second_corr.length
    this.state = {
      legendIndex: props.legendIndex,
      data: props.highestData,
      map: React.createRef(),
      geoJsonRef: React.createRef(),
      featureId: React.createRef(),
      featureBounds: [],
      prevFeatureId: [props.country,],
      tiffUrl: props.tiffUrl,
      // position: props.position ? props.position : [second_avg, first_avg],
      position: props.position ? props.position : coord_zoom_config[this.country]["coordinates"],
      featureLevel: props.featureLevel ? props.featureLevel : 0,
      fL: 0,
      interactiveFocus: false,
      fromWhere: props.fromWhere,
      onMapUpdate: props.onMapUpdate ? props.onMapUpdate : () => {},
      // zoom: 6,
      zoom: coord_zoom_config[this.country]["zoom"],
      currentFeature: props.country,
      interactiveClear: false
    };
  }

  componentDidMount() {
    if(this.state.fromWhere == "interactive") {
      this.initializeData(this.state.featureLevel)
    }
    if (!(this.state.fromWhere == "interactive") && this.state.fL==0 && this.country) {
      this.initalChildren();
    }
  }

  async getShapeFile(country, level) {
    const endpoint = `${server_config["LOCALHOST_SERVER_URL"]}/geoTiff/level/${country}/${level}/`
    const resJSON = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
      return res.json()
    }).then((resJSON) => {
      return resJSON;
    }).catch((err) => {
      console.log(err);
    });
    return resJSON;
  }

  async getChildrenShapeFile(country, parentFeatureId) {
    const endpoint = `${server_config["LOCALHOST_SERVER_URL"]}/geoTiff/childrenLevel/${country}/${parentFeatureId}/`
    const resJSON = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
      return res.json()
    }).then((resJSON) => {
      return resJSON;
    }).catch((err) => {
      console.log(err);
    });
    return resJSON;
  }

  async initializeData(level) {
    this.state.data = await this.getShapeFile(this.country, level)
    this.state.featureId = await this.state.data.features[0].current;
    this.state.highestFeatureId = this.country;
  }

  async initalChildren() {
    const tempData = await this.getChildrenShapeFile(this.country, this.country)
    this.state.data = await tempData;
    this.state.currentFeature = await this.country
    if (this.state.geoJsonRef.current) {
      await this.state.geoJsonRef.current.clearLayers();
      await this.state.geoJsonRef.current.addData(this.state.data);
    }
    for (let i = 0; i < this.refOtherGraphs.length; i++) {
      let tempReference;
      if (this.refOtherGraphs[i].current) {
        tempReference = await this.refOtherGraphs[i].current.state;
      }
      if (tempReference) {
        tempReference.currentFeature = await this.country
        tempReference.data = await tempData;
        if (tempReference.geoJsonRef.current) {
          await tempReference.geoJsonRef.current.clearLayers();
          await tempReference.geoJsonRef.current.addData(this.state.data);
        }
      }
    }
  }

  async setTiffUrl(tiffUrl, level, featureId) {
    this.state.tiffUrl = tiffUrl;
    await getTiff_HTTP(tiffUrl, this.country, level, featureId)
  }

  setLegendIndex(legendIndex) {
    this.state.legendIndex = legendIndex;
  }

  setData(data) {
    this.state.data = data;
    this.state.geoJsonRef.current.clearLayers();
    this.state.geoJsonRef.current.addData(this.state.data);
  }

  getHighestFeatureId() {
    return this.state.highestFeatureId;
  }

  clearData() {
    this.state.geoJsonRef.current.clearLayers();
  }

  async setFeatureLevel(level) {
    if (level != -1) {
      this.state.featureLevel = level;
      this.state.interactiveClear = false;
      await this.initializeData(level);
      await this.state.geoJsonRef.current.clearLayers();
      await this.state.geoJsonRef.current.addData(this.state.data);
    } else {
      this.state.featureLevel = level;
      this.state.interactiveClear = true;
      await this.state.geoJsonRef.current.clearLayers();
    }
  }

  color = (val) => {
    let index = this.state.legendIndex;
    let legend = this.legendsData[index];

    let n = legend["legend_colors"].length;
    if (isNaN(val[0])) return "transparent";
    for (let i = n - 1; i >= 0; i--) {
      var upper_limit = legend["legend_ranges"][i][1];
      var lower_limit = legend["legend_ranges"][i][0];
      var color = legend["legend_colors"][i];
      if (
        (lower_limit == "-INF" || lower_limit <= val[0]) &&
        (upper_limit == "INF" || val[0] < upper_limit)
      ) {
        return color;
      }
    }
    return "transparent";
  };

  options = {
    pixelValuesToColorFn: (values) => this.color(values),
    resolution: 64,
    opacity: 1.0,
  };

  handleEachFeature(feature, layer) {

    if (!this.disableLevelButton) {
      layer.bindPopup(feature.properties.name);

      layer.on("mouseover", function (e) {
        layer.openPopup();
        layer.setStyle({
          fillColor: "blue",
        });
      });

      layer.on("mouseout", function (e) {
        layer.closePopup();
        layer.setStyle({
          fillColor: "transparent",
          color: "black",
          fillOpacity: 0.2,
        });
      });

      layer.on({
        click: async (e) => {
          this.state.onMapUpdate(feature.current);
          // check fromWhere //
          if (this.state.fromWhere == "interactive") {
            if(this.state.interactiveClear) {
              this.state.geoJsonRef.current.clearLayers();
              this.forceUpdate();
            } else {
              if (!this.state.interactiveFocus) {
                this.state.geoJsonRef.current.clearLayers();
                this.state.geoJsonRef.current.addData(feature);
                this.state.map.current.fitBounds(e.target.getBounds());
                this.state.featureBounds.push(this.state.map.current.getBounds());
                this.state.featureId = feature.current;
                this.state.currentFeature = e.target.feature.properties.current
                this.state.prevFeatureId.push(this.state.currentFeature);
                this.state.interactiveFocus = true;
                await this.setTiffUrl(this.state.tiffUrl, this.state.featureLevel, this.state.currentFeature)
                this.forceUpdate();
              }
            }
          } else {

            if (this.state.fL < country_adm_level_config[this.country]) {
              this.state.geoJsonRef.current.clearLayers();
              for (let i = 0; i < this.refOtherGraphs.length; i++) {
                this.refOtherGraphs[i].current.state.geoJsonRef.current.clearLayers();
              }
              if (this.state.fL == country_adm_level_config[this.country] - 1) {
                const tempData = feature;
                this.state.data = tempData;
                for (let i = 0; i < this.refOtherGraphs.length; i++) {
                  this.refOtherGraphs[i].current.state.data = tempData;
                }
              } else {
                const tempData = await this.getChildrenShapeFile(this.country, e.target.feature.properties.current)
                this.state.data = tempData;
                for (let i = 0; i < this.refOtherGraphs.length; i++) {
                  this.refOtherGraphs[i].current.state.data = tempData;
                }
              }
              this.state.geoJsonRef.current.addData(this.state.data);
              this.state.map.current.fitBounds(e.target.getBounds());
              this.state.featureBounds.push(this.state.map.current.getBounds());
              this.state.featureId = feature.current;
              this.state.currentFeature = e.target.feature.properties.current
              this.state.prevFeatureId.push(this.state.currentFeature);
              this.state.fL = this.state.fL + 1;
              for (let i = 0; i < this.refOtherGraphs.length; i++) {
                this.refOtherGraphs[i].current.state.geoJsonRef.current.addData(this.refOtherGraphs[i].current.state.data);
                this.refOtherGraphs[i].current.state.map.current.fitBounds(e.target.getBounds());
                this.refOtherGraphs[i].current.state.featureBounds.push(this.refOtherGraphs[i].current.state.map.current.getBounds());
                this.refOtherGraphs[i].current.state.featureId = feature.current;
                this.refOtherGraphs[i].current.state.currentFeature = e.target.feature.properties.current
                this.refOtherGraphs[i].current.state.prevFeatureId.push(this.refOtherGraphs[i].current.state.currentFeature);
                this.refOtherGraphs[i].current.state.fL = this.refOtherGraphs[i].current.state.fL + 1;
              }
              await this.setTiffUrl(this.state.tiffUrl, this.state.fL, this.state.currentFeature);
              for (let i = 0; i < this.refOtherGraphs.length; i++) {
                await this.refOtherGraphs[i].current.setTiffUrl(this.refOtherGraphs[i].current.state.tiffUrl, this.refOtherGraphs[i].current.state.fL, this.refOtherGraphs[i].current.state.currentFeature);
              }
              this.forceUpdate();
              for (let i = 0; i < this.refOtherGraphs.length; i++) {
                this.refOtherGraphs[i].current.forceUpdate()
              }
            }
          }
        },
      });
    }
  }

  render() {
    let features = {
      fromWhere: this.state.fromWhere,
      featureLevel: this.state.featureLevel,
      featureId: this.state.currentFeature,
    };
    let zoom = 0;
    let dragF = true;
    /* Storing user's device details in a variable*/
    let details = navigator.userAgent;

    /* Creating a regular expression 
    containing some mobile devices keywords 
    to search it in details string*/
    let regexp = /android|iphone|kindle|ipad/i;

    /* Using test() method to search regexp in details
    it returns boolean value*/
    let isMobileDevice = regexp.test(details);

    if (isMobileDevice) {
      zoom = 6;
      dragF = false;
    } else {
      zoom = this.state.zoom;
      dragF = true;
    }

    return (
      <>
        <MapContainer
          id={"map"}
          center={this.state.position}
          scrollWheelZoom={false}
          dragging={dragF}
          zoom={zoom}
          zoomControl={this.zoomControl}
          minZoom={5}
          maxZoom={9}
          style={{
            background: "white",
            height: "35rem",
            maxHeight: "35rem",
            minHeight: "20rem",
            zIndex: 10,
          }}
          ref={this.state.map}
          preferCanvas={true}
        >
          {!this.disableLevelButton ? (
            <LevelButton
              mapPointer={this}
              refOtherGraphs={this.refOtherGraphs}
              makeCountryChoice={this.makeCountryChoice}
            />
          ) : (
            <></>
          )}
          {this.enableLegends ? (
            <Legends
              mapPointer={this}
              value1={this.state.legendIndex}
              legend={this.legendsData[this.state.legendIndex]}
            />
          ) : (
            <></>
          )}

          {this.state.map && (
            <GeotiffLayer
              url={this.state.tiffUrl}
              options={this.options}
              features={features}
              country={this.country}
              interactiveClear={this.state.interactiveClear}
            />
          )}
          <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}" />
          {this.state.map && (
            <GeoJSON
              ref={this.state.geoJsonRef}
              data={this.state.data}
              onEachFeature={this.handleEachFeature}
              style={{
                fillColor: "transparent",
                color: "black",
                weight: 0.9,
                zIndex: 10,
              }}
            />
          )}
        </MapContainer>
      </>
    );
  }
}
