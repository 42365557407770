import React from "react";
import logo1 from "../static/img/UMassAmherstLogo.png";
import logo2 from "../static/img/MSUWordmark.jpg";
import logo3 from "../static/img/SIILLogo.jpg";
import logo4 from "../static/img/USAID.jpg";
import logo5 from "../static/img/kansas-state-university-vector-logo.jpg";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

// this is the footer page display contact info and have also logos
const Footer = () => {
  return (
    // <>
    //   <hr className="mt-5" />
    //   <div className="mt-10 font-Arialbold ">
    //     {/* logos  */}
    //     <div className="grid lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-3 xs:grid-cols-2 grid-cols-2 justify-between items-center">
    //       <img src={logo4} className="my-1 p-10 "/>
    //       <img src={logo3} className="my-1 p-10"/>
    //       <img src={logo5} className="my-1 p-10"/>
    //       <img src={logo2} className="my-1 p-10"/>
    //       <img src={logo1} className="my-1 p-10"/>
    //     </div>
    //     {/* contact information */}
    //     <div className="bg-[#e6ddd5] py-5 flex flex-col justify-center items-center flex-wrap">
    //       <h1 className="lg:text-3xl text-xl py-2">For technical queries:</h1>
    //       <h2 className="lg:text-2xl text-md text-center py-2">
    //         Remote Sensing in Hydrology and Agriculture Laboratory, Michigan
    //         State University{" "}
    //       </h2>
    //       <p className="lg:text-2xl text-md py-2">
    //         524 S. Shaw Lane East Lansing, MI 48824
    //       </p>
    //       <div className="flex space-x-3 py-2 lg:text-2xl text-md">
    //         <FontAwesomeIcon className="lg:h-8 lg:w-8 w-4 h-4" icon={faPhone} />{" "}
    //         <span>: (517) 432-1257</span>
    //         <FontAwesomeIcon className="lg:h-8 lg:w-8 w-4 h-4" icon={faEnvelope} />{" "}
    //         <span>: dasnaren@msu.edu</span>
    //       </div>
    //     </div>
    //     {/* this is the devloper contact inforamtion  */}
    //     <div className="bg-[#D37D28]">
    //       <p className=" p-5 flex justify-center text-center items-center">
    //         Website implemented by: JASPL (contact@jaspl.tech)
    //       </p>
    //     </div>
    //   </div>
    // </>

    <>
      <hr className="mt-5" />
      {/* logos  */}
      <div className="grid lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-3 xs:grid-cols-2 grid-cols-2 justify-between items-center">
        <img src={logo4} width="80%" className="my-1 p-10" />
        <img src={logo3} width="80%" className="my-1 p-10" />
        <img src={logo5} width="80%" className="my-1 p-10" />
        <img src={logo2} width="80%" className="my-1 p-10" />
        <img src={logo1} width="80%" className="my-1 p-10" />
      </div>
      <div className="relative bg-[#e6ddd5] pt-8">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap text-center lg:text-left">
            <div className="w-full lg:w-6/12 px-4 pb-6">
              <h4 className="text-3xl font-semibold">For technical queries</h4>
              <h5 className="text-lg mt-2">
                Remote Sensing in Hydrology and Agriculture Laboratory, Michigan
                State University, 524 S. Shaw Lane East Lansing, MI 48824
              </h5>
              <div className="mt-6 lg:mb-0 mb-6 flex flex-col">
                <div className="flex flex-row mx-auto md:mx-0">
                  <FontAwesomeIcon
                    className="lg:h-8 lg:w-8 w-4 h-4 pb-3"
                    icon={faPhone}
                  />{" "}
                  <span className="md:text-xl text-base pl-2 my-auto -mt-1 md:mt-0">(517) 432-1257</span>
                </div>
                <div className="flex flex-row mx-auto md:mx-0">
                <FontAwesomeIcon
                  className="lg:h-8 lg:w-8 w-4 h-4 py-3"
                  icon={faEnvelope}
                />{" "}
                <span className="md:text-xl text-base pl-2 my-auto">dasnaren@msu.edu</span>
                </div>
              </div>
            </div>
            <div className="w-full lg:w-6/12 px-4">
              <div className="flex flex-wrap items-top mb-6">
                <div className="w-full lg:w-4/12 px-4 ml-auto">
                  <span className="block uppercase text-sm font-semibold mb-2">
                    Useful Links
                  </span>
                  <ul className="list-unstyled">
                    <li>
                      <span className="text-gray-600 hover:text-gray-800 font-semibold block pb-2 text-sm">
                        <NavLink to="/">Quick View</NavLink>
                      </span>
                    </li>
                    <li>
                      <span className="text-gray-600 hover:text-gray-800 font-semibold block pb-2 text-sm">
                        <NavLink to="/interactive">Interactive Maps</NavLink>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap items-center md:justify-between justify-center bg-[#D37D28] py-5">
          <div className="w-full md:w-4/12 px-4 mx-auto text-center">
            <div className="text-base text-gray-900 font-semibold py-1">
              Website implemented by: JASPL <span>(contact@jaspl.tech)</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
