import myCache, { cacheConfig } from "./Cache.js";
import { Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import InteractiveMap from "./components/InteractiveMap";
import Reports from "./components/Reports";
import Spatial from "./components/Spatial";
import Footer from "./components/Footer";
import CropYield from "./components/CropYield";
import Drought from "./components/Drought";
import CropYieldInteractive from "./components/CropYieldInteractive";
import Modal from "./components/Modal";
import React, { Component, useEffect } from "react";
import { getLegendsData } from "./FetchReq";
import CountryChoice from "./components/CountryChoice.js";
const server_config = require("./config/server.config.json");

function App() {
  // Fetching Legends Data One Time //
  const [legendsData, setLegendsData] = React.useState(null);
  let [country, setCountry] = React.useState(null);

  useEffect(() => {
    const endpoint = server_config["LOCALHOST_SERVER_URL"] +"/webhooks/legendsInfo"
    fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setLegendsData(data);
          // console.log(data)
        });
      }
    });
  }, []);

  function makeCountryChoice(country_tag) {
    if (country_tag) {
      const endpoint = `${server_config["LOCALHOST_SERVER_URL"]}/geoTiff/level/${country_tag}/0`
      fetch(endpoint, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => {
        if (res.status === 200) {
          res.json().then((countryShapefile) => {
            myCache.set("countryShapefile",countryShapefile);
            // console.log(country_tag)
            myCache.set("country",country_tag);
            setCountry(country_tag);
          });
        }
      }).catch((err) => {
        console.log(err);
      });
    } else {
      myCache.set("countryShapefile", null);
      myCache.set("country",country_tag);
      setCountry(country_tag);
    }
  }

  myCache.set("legendsData", legendsData);
  
  return (
    <>
      {/* To navigat between pages  */}
      {(legendsData !== null && country !== null) ? (
        <>
          <Navbar />
          <Modal />
          <Routes>
            <Route path="/" element={<Drought makeCountryChoice={makeCountryChoice}/>} />
            <Route path="/interactive" element={<InteractiveMap makeCountryChoice={makeCountryChoice}/>} />
          </Routes>
          <Footer />
        </>
      ) : (
        <>
          <Navbar />
          <Modal />
          <CountryChoice makeCountryChoice={makeCountryChoice}/>
          <Footer />
        </>
      )}
    </>
  );
}

export default App;
