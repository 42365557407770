import myCache from "../Cache";
import server_config from "../config/server.config.json";

// Toggle the Modal //
function displayError(error) {
  document.getElementById("modal_text").innerHTML = error;
  document.getElementById("modal").classList.remove("hidden");
  document.getElementById("modal").classList.add("flex");

  setTimeout(() => {
    document.getElementById("modal").classList.add("hidden");
    document.getElementById("modal").classList.remove("flex");
  }, 1000);
}

function toggleMobileMenu() {
  document.getElementById("mobile-menu").classList.toggle("hidden");
}

/*
This function generates tiff file url which is used in GeoTiffLayer.js file.
index: legend index as per legendsInfo.json file.
prediction: Prediction Type (nowcast or forecast)
date: date string in {yyyymmdd} format
region: this variable will be used in further work
*/
const pathGenerator = ({ index, prediction, date, country_code }) => {
  
  // Retrieve data for legend
  let legend = myCache.get("legendsData")["legendsInfo"][index];

  // Fetch ensembleNumber.
  let ensembleNumber =
    prediction === "nowcast"
      ? legend.nowcastEnsembleNumber
      : legend.forecastEnsembleNumber;

  // Define fileName as per structure defined variableCode_date_layer_ensembleNumber.extension
  let fileName = `${legend.variableCode}_${date}_${legend.layerPosition}_${ensembleNumber}${legend.extension}`;

  let tiffUrl = `${country_code}/${prediction}/${legend.subDirectory}/${fileName}`;

  return tiffUrl;
};

/*
Some functions uses date format as yyyymmdd and some as yyyy-mm-dd, this function returns date string as per need.
*/
function dateToString(date, dash) {
  if (!dash)
    return `${date.getFullYear()}${
      (date.getMonth() + 1 < 10 ? "0" : "") + (date.getMonth() + 1)
    }${(date.getDate() < 10 ? "0" : "") + date.getDate()}`;
  else {
    return `${date.getFullYear()}-${
      (date.getMonth() + 1 < 10 ? "0" : "") + (date.getMonth() + 1)
    }-${(date.getDate() < 10 ? "0" : "") + date.getDate()}`;
  }
}

/*
 Generate API Endpoint URL
*/
function generateAPIEndpointURL(params, endpoint) {
  let base_url =
    server_config["LOCALHOST_SERVER_URL"] +
    "/" +
    endpoint;
  for (let key in params) {
    base_url += "/" + params[key];
  }
  return base_url;
}

export { dateToString };
export { pathGenerator };
export { displayError };
export { toggleMobileMenu };
export { generateAPIEndpointURL };
